export const BiReportLink = "https://report.max-moscow.com/";

export const Gender = [
    {id: 1, eng: 'Male', rus: 'Мужской'},
    {id: 2, eng: 'Female', rus: 'Женский'},
];

export const Status = [
    {id: 0, eng: 'Active', rus: 'Активный'},
    {id: 1, eng: 'Blocked', rus: 'Заблокированный'},
];

export const AutoStatus = [
    {id: 1, eng: 'Active', rus: 'Подключена'},
    {id: 2, eng: 'Active', rus: 'Отключена'},
    {id: 3, eng: 'Active', rus: 'На согласовании'},
    {id: 5, eng: 'Active', rus: 'Вакансия'},
    {id: 7, eng: 'Active', rus: 'Обслуживается'},
    {id: 9, eng: 'Active', rus: 'Закрыта'},
    {id: 11, eng: 'Active', rus: 'Отклонена'},
    {id: 14, eng: 'Active', rus: 'Временно закрыта'},
];

export const ReportStatus = [
    {id: 0, eng: 'Active', rus: 'Не проверен'},
    {id: 1, eng: 'Active', rus: 'Принят'},
    {id: 2, eng: 'Active', rus: 'Отклонен'},
    {id: 3, eng: 'Active', rus: 'Непринятие подтверждено'},
    {id: 4, eng: 'Active', rus: 'Непринятие отменено'},
];

export const ReportStatusCutted = [
    {id: 0, eng: 'Active', rus: 'Не проверен'},
    {id: 1, eng: 'Active', rus: 'Принят'},
];

export const QuestionStatus = [
    {id: 1, eng: 'New', rus: 'Новая'},
    {id: 2, eng: 'Completed', rus: 'Выполнена'},
    {id: 3, eng: 'Canceled', rus: 'Отменена'},
    {id: 4, eng: 'Expired', rus: 'Просрочена'},
];

export const PropertyNames = [
    {eng: 'Group', rus: 'Сеть'},
    {eng: 'Address', rus: 'Адрес'},
];

export const YesNo = [
    {id: 0, eng: 'No', rus: 'Нет', bool: false},
    {id: 1, eng: 'Yes', rus: 'Да', bool: true},
];

export const FeatureType = [
    {id: 'int', eng: 'int', rus: 'Целое'},
    {id: 'string', eng: 'string', rus: 'Строка'},
    {id: 'date', eng: 'date', rus: 'Дата'},
    {id: 'time', eng: 'time', rus: 'Время'},
    {id: 'photo', eng: 'photo', rus: 'Фото'},
    {id: 'bool', eng: 'bool', rus: 'Булево'},
    {id: 'enum', eng: 'enum', rus: 'Перечисление'},
    {id: 'float', eng: 'float', rus: 'С плавающей точкой'},
    {id: 'app_link', eng: 'app_link', rus: 'Ссылка на приложение'},
];

export const RoleType = [
    {id: 'admin', eng: 'Admin', rus: 'Администратор'},
    {id: 'exec', eng: 'Executor', rus: 'Исполнитель'},
    {id: 'check', eng: 'Checking', rus: 'Проверяющий'},
    {id: 'checkAndExec', eng: 'Checking And Execution', rus: 'Проверяющий и исполнитель'},
    {id: 'control', eng: 'Controlling', rus: 'Контролирующий'},
    {id: 'integration', eng: 'Integration', rus: 'Интеграция'}
];

export const RequiredType = [
    {id: 0, eng: 'not required', rus: 'Не обязательный'},
    {id: 1, eng: 'required', rus: 'Обязательный'},
    {id: 2, eng: 'required dont cancel', rus: 'Обязательный, нельзя отменить'},
];

export const TimeZone = [
    {id: -12, rus: '-12 часов'},
    {id: -11, rus: '-11 часов'},
    {id: -10, rus: '-10 часов'},
    {id: -9, rus: '-9 часов'},
    {id: -8, rus: '-8 часов'},
    {id: -7, rus: '-7 часов'},
    {id: -6, rus: '-6 часов'},
    {id: -5, rus: '-5 часов'},
    {id: -4, rus: '-4 часа'},
    {id: -3, rus: '-3 часа'},
    {id: -2, rus: '-2 часа'},
    {id: -1, rus: '-1 час'},
    {id: 0, rus: '0'},
    {id: 1, rus: '+1 час'},
    {id: 2, rus: '+2 часа'},
    {id: 3, rus: '+3 часа'},
    {id: 4, rus: '+4 часа'},
    {id: 5, rus: '+5 часов'},
    {id: 6, rus: '+6 часов'},
    {id: 7, rus: '+7 часов'},
    {id: 8, rus: '+8 часов'},
    {id: 9, rus: '+9 часов'},
    {id: 10, rus: '+10 часов'},
    {id: 11, rus: '+11 часов'},
    {id: 12, rus: '+12 часов'},
];

export const AttribTypes = Object.freeze({
    'checkbox': 'checkbox',
    'color': 'color',
    'date': 'date',
    'file': 'file',
    'select': 'select',
    'slider': 'slider',
    'textField': 'textField',
    'textArea': 'textArea',
    'number': 'number',
    'autocomplete': 'autocomplete',
    'coordinates': 'coordinates',
    'time': 'time',
});

const localEndpoints = process.env.VUE_APP_SFA_PORTS === "DEVELOPMENT";

export const ServicePorts = Object.freeze({
    'users': localEndpoints ? ':8181' : '/api_v2/users',
    'projects': localEndpoints ? ':8182' : '/api_v2/projects',
    'questionnaire': localEndpoints ? ':8183' : '/api_v2/questionnaires',
    'reasons': localEndpoints ? ':8184' : '/api_v2/reasons',
    'mobile': localEndpoints ? ':8185' : '/api_v2/mobile',
    'outlets': localEndpoints ? ':8186' : '/api_v2/outlets',
    'project_outlets': localEndpoints ? ':8187' : '/api_v2/project_outlets',
    'routes': localEndpoints ? ':8188' : '/api_v2/routes',
    'schedule': localEndpoints ? ':8189' : '/api_v2/schedule',
    'files': localEndpoints ? ':8190' : '/api_v2/files',
    'sku': localEndpoints ? ':8191' : '/api_v2/sku',
    'reports': localEndpoints ? ':8192' : '/api_v2/reports',
    'materials': localEndpoints ? ':8193' : '/api_v2/materials',
    'import': localEndpoints ? ':8194' : '/api_v2/import',
});

export const QuestionIcons = {
    "int": "numeric",
    "string": "format-text",
    "date": "calendar-range",
    "photo": "file-image-outline",
    "bool": "sort-bool-ascending",
    "enum": "format-list-bulleted",
    "float": "sort-numeric-ascending",
    "time": "clock-outline",
    "obj": "source-branch",
    "photo_sku_category": "image-multiple-outline",
    "app_link": "link"
}